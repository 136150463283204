<template>
  <div>
    <div v-if="this.company" class="menu-items">
      <a
        v-if="this.company.role === 'AFA'"
        href="/affiliate/search"
        class="search-icon cursor-pointer"
        :class="this.menuSelected.affiliateSearch && 'search-active'"
        >{{ $t("searchName") }}</a
      >
      <a
        v-if="this.company.role !== 'AFA'"
        href="/affiliate/dashboard"
        class="dashboard cursor-pointer"
        :class="this.menuSelected.dashboard && 'dashboard-active'"
        >{{ $t("dashboardName") }}</a
      >
      <a
        v-if="hasCardProduct"
        href="/affiliate/cardTransactions"
        class="cardTransactions cursor-pointer"
        :class="this.menuSelected.cardTransactions && 'cardTransactions-active'"
        >{{ $t("cardTransactionsName") }}</a
      >
      <a
        v-if="hasPaperProduct"
        href="/affiliate/repayment"
        class="repayment cursor-pointer"
        :class="this.menuSelected.repayment && 'repayment-active'"
        >{{ $t("repaymentName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/invoices"
        class="invoices cursor-pointer"
        :class="this.menuSelected.invoices && 'invoices-active'"
        >{{ $t("invoicesName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/workingPoints"
        class="workingPoints cursor-pointer"
        :class="this.menuSelected.workingPoints && 'workingPoints-active'"
        >{{ $t("workingPoints") }}</a
      >
      <a
        v-if="hasCardProduct"
        href="/affiliate/pos"
        class="pos cursor-pointer"
        :class="this.menuSelected.pos && 'pos-active'"
        >{{ $t("pos") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/stickers"
        class="stickers cursor-pointer"
        :class="this.menuSelected.stickers && 'stickers-active'"
        >{{ $t("stickersName") }}</a
      >
      <!-- <a
        v-if="this.company.company_id !== 0"
        href="affiliate/offers"
        class="offers"
        :class="this.menuSelected.offers && 'offers-active'"
        >{{ $t("offersLabel") }}</a
      > -->
      <a
        href="/affiliate/account"
        class="amanage cursor-pointer"
        :class="this.menuSelected.account && 'amanage-active'"
        >{{ $t("settingsName") }}</a
      >
      <a
        v-if="
          this.company.company_id !== 0 &&
          !user.username.includes('@') &&
          this.company.role !== 'AFA' &&
          this.user.companies[0].user_config &&
          this.user.companies[0].user_config.roles.includes('EDITOR')
        "
        href="/company/manage"
        class="cmanage cursor-pointer"
        :class="this.menuSelected.manageCompany && 'cmanage-active'"
        >{{ $t("companyManagementTitle") }}</a
      >
      <!-- <a
        v-if="contractStatus > -1"
        href="affiliate/additionalDocuments"
        class="additionalDocuments"
        :class="
          this.menuSelected.additionalDocuments && 'additionalDocuments-active'
        "
        >{{ $t("additionalDocuments") }}</a
      > -->
      <a
        href="/affiliate/contact"
        class="contact cursor-pointer"
        :class="this.menuSelected.contact && 'contact-active'"
        >{{ $t("contactName") }}</a
      >
      <!-- <a
        href="affiliate/terms"
        class="term"
        :class="this.menuSelected.terms && 'term-active'"
        >{{ $t("termsAndConditionsName") }}</a
      >
      <a
        href="affiliate/gdpr"
        class="policy"
        :class="this.menuSelected.gdpr && 'policy-active'"
        >{{ $t("personalDataPolicyName") }}</a
      > -->

      <a href="#" class="logout" @click="logout()">{{ $t("logoutName") }}</a>
    </div>
    <div class="socials">
      <a href="https://www.facebook.com/UpRomania" class="facebook"></a>
      <a
        href="https://www.linkedin.com/company/up-romania"
        class="linkedin"
      ></a>
      <a
        href="https://www.youtube.com/c/UpRomaniaBucuresti"
        class="youtube"
      ></a>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "MenuItemsAffiliate",
  props: {
    company: Object,
    menuSelected: {
      dashboard: false,
      cardTransactions: false,
      repayment: false,
      invoices: false,
      workingPoints: false,
      pos: false,
      statistics: false,
      stickers: false,
      account: false,
      contact: false,
      terms: false,
      gdpr: false,
      affiliateSearch: false,
      additionalDocuments: false,
      offers: false
    },
    hasCardProduct: Boolean,
    hasPaperProduct: Boolean,
    // contractStatus: Number,
    user: Object
  },
  methods: {
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("user_role");
      Cookies.remove("token");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
  mounted() {
    if (this.menuSelected.companySearch) {
      this.company.companyId = "0";
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
