<template>
  <div>
    <div v-if="this.dataLoaded">
      <div class="menu">
        <a href="/company" class="logo cursor-pointer">
          <img src="@/assets/images/logo-up.png" />
        </a>
        <!-- :contractStatus="contractStatus" -->
        <MenuItems
          :company="this.company"
          :menuSelected="this.menuSelected"
          :hasCardProduct="hasCardProduct"
          :hasPaperProduct="hasPaperProduct"
          :user="this.user"
        />
      </div>

      <div class="menu-mobile">
        <div class="user-mobile">
          <div class="close-menu" @click="openMenu(false)">
            <img src="@/assets/images/x.svg" />
          </div>
          <div
            class="openNotifications"
            @click="showModalPopup('modal_popup_mobileNotifications', true)"
          >
            <div v-if="this.readMessagesCount > 0" class="unread-notifications">
              <span>{{ this.readMessagesCount }}</span>
            </div>
            <img src="@/assets/images/clopotel-alb.svg" />
          </div>

          <div id="modal_popup_mobileNotifications" class="modal-popup">
            <div class="modal-popup-content">
              <a
                @click="
                  showModalPopup('modal_popup_mobileNotifications', false)
                "
                class="close-button"
              >
                <img src="@/assets/images/close-button.svg" />
              </a>
              <div class="notifications">
                <div
                  v-for="message in this.affiliateMessages"
                  :key="message.id"
                  class="main-notification unread"
                  @click="isReadNotification(message)"
                  :class="{ read: message.read }"
                >
                  <div class="notification-title">
                    <img src="@/assets/images/icon5.png" alt />
                    <h6>{{ getExpireAmountDateFormat(message.dateIssued) }}</h6>
                  </div>
                  <div class="notification-content">
                    <p>{{ message.message }}</p>
                  </div>
                  <div class="notification-footer">
                    <p>
                      <span>Valabilitate:</span>
                      {{ getExpireAmountDateFormat(message.validFrom) }} -
                      {{ getExpireAmountDateFormat(message.validTo) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img src="@/assets/images/linie.png" />
          </div>
          <div>
            <img src="@/assets/images/settings.png" />
          </div>
          <div>
            <a href="#">{{ this.affiliate.name | titleCase }}</a>
          </div>
        </div>
        <!-- :contractStatus="contractStatus" -->
        <MenuItems
          :company="this.company"
          :menuSelected="this.menuSelected"
          :hasCardProduct="hasCardProduct"
          :hasPaperProduct="hasPaperProduct"
          :user="this.user"
        />
      </div>

      <div class="container-right">
        <div
          class="barTop"
          :class="menuSelected.dashboard ? 'view' : 'bar-top-white'"
        >
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="@/assets/images/logo-up.png" />
          </div>
          <div
            class="menu-white"
            v-if="menuSelected.dashboard"
            @click="openMenu(true)"
          >
            <img src="@/assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav" v-if="!menuSelected.dashboard">
            <div class="arrow-back" @click="goBack()">
              <img src="@/assets/images/arrow-back.svg" />
            </div>
            <div class="page-title">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="@/assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <TopBar :company="this.affiliate" :user="this.user" />
        </div>

        <div
          class="content"
          :class="this.menuSelected.dashboard && 'dashboard-beneficiary'"
        >
          <AffiliateSearch
            :affiliate="this.affiliate"
            v-if="
              this.menuSelected.affiliateSearch && this.company.role === 'AFA'
            "
          />
          <Dashboard
            :affiliate="this.affiliate"
            :user="this.user"
            v-if="this.menuSelected.dashboard && this.company.role !== 'AFA'"
          />
          <CardTransactions
            :affiliate="this.affiliate"
            v-if="this.menuSelected.cardTransactions && hasCardProduct"
          />
          <Reimbursement
            :affiliate="this.affiliate"
            v-if="this.menuSelected.repayment && hasPaperProduct"
          />
          <Invoices
            :affiliate="this.affiliate"
            :userRole="this.company.role"
            :user="this.user"
            v-if="this.menuSelected.invoices"
          />
          <Stickers
            :affiliate="this.affiliate"
            v-if="this.menuSelected.stickers"
          />
          <WorkingPoints
            :affiliate="this.affiliate"
            v-if="this.menuSelected.workingPoints"
          />
          <POS
            :affiliate="this.affiliate"
            :user="this.user"
            v-if="this.menuSelected.pos && hasCardProduct"
          />
          <CompanySettings
            :companyDetails="this.affiliate"
            :user="this.user"
            :userRole="this.company.role"
            v-if="this.menuSelected.account"
          />
          <Offers
            :companyDetails="this.affiliate"
            :user="this.user"
            v-if="this.menuSelected.offers"
          />
          <LinkFrame
            :urlAddress="this.config.additionalDocuments"
            v-if="this.menuSelected.additionalDocuments"
          />
          <Contact
            :affiliate="this.affiliate"
            v-if="this.menuSelected.contact && this.company.role !== 'FAC'"
          />
          <ManageCompany
            :companyDetails="this.affiliate"
            :user="this.user"
            :userType="this.company.role"
            v-if="this.menuSelected.manageCompany"
          />
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')">
        </p>
      </div>
    </div>
    <div id="modal_popup_noProducts" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_noProducts', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div>
          <p>{{ $t("noProductsText") }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment/src/moment";
import MenuItems from "@/components/company/affiliate/MenuItems.vue";
import TopBar from "@/components/company/TopBar.vue";
import AffiliateSearch from "@/components/company/affiliate/AffiliateSearch.vue";
import Dashboard from "@/components/company/affiliate/Dashboard.vue";
import CardTransactions from "@/components/company/affiliate/CardTransactions.vue";
import Invoices from "@/components/company/affiliate/Invoices.vue";
import Stickers from "@/components/company/affiliate/Stickers.vue";
import WorkingPoints from "@/components/company/affiliate/WorkingPoints.vue";
import POS from "@/components/company/affiliate/POS.vue";
import CompanySettings from "@/components/company/CompanySettings.vue";
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
import Contact from "@/components/company/affiliate/Contact.vue";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceUser from "@/http/http-user.js";
import Cookies from "js-cookie";
import $ from "jquery";
import httpService from "@/http/http-common.js";
import Reimbursement from "@/components/company/affiliate/Reimbursement.vue";
import Offers from "@/components/company/affiliate/Offers.vue";
import ManageCompany from "@/components/company/ManageCompany.vue";
import ModalPopupNPS from '@/components/fragments/ModalNps.vue';

export default {
  components: {
    MenuItems,
    TopBar,
    LinkFrame,
    Dashboard,
    CardTransactions,
    Invoices,
    Stickers,
    WorkingPoints,
    POS,
    CompanySettings,
    Contact,
    Reimbursement,
    AffiliateSearch,
    Offers,
    ManageCompany,
    ModalPopupNPS
  },
  props: {
    user: Object,
    company: Object
  },
  data() {
    return {
      userLink: "",
      token: "",
      affiliate: null,
      affiliateMessages: null,
      dataLoaded: false,
      menuSelected: {
        dashboard: false,
        cardTransactions: false,
        repayment: false,
        invoices: false,
        workingPoints: false,
        pos: false,
        statistics: false,
        stickers: false,
        account: false,
        contact: false,
        terms: false,
        gdpr: false,
        additionalDocuments: false,
        affiliateSearch: false,
        offers: false,
        manageCompany: false
      },
      config: JSON.parse(sessionStorage.getItem("affiliate_jsonFile")),
      isOpen: false,
      isOpenNotification: false,
      readMessagesCount: 0,
      feedbackMessage: null,
      successSentFeedback: false,
      sentFeedbackError: null,
      // contractStatus: null,
      hasElectronicProduct: false,
      hasElectronicDejunProduct: false,
      hasCheckPaperProduct: false
      // uuid: "",
      // emailNotif: ""
    };
  },
  methods: {
    orderProducts() {
      if (this.affiliate.products) {
        this.affiliate.products.forEach(product => {
          if (product.name === "Up Dejun") {
            product.order = 1;
          } else if (product.name === "Up Cadou") {
            product.order = 2;
          } else if (product.name === "Up Cadou STIM") {
            product.order = 3;
          } else if (product.name === "Up Vacanta") {
            product.order = 4;
          } else if (product.name === "Up Cultura") {
            product.order = 5;
          } else if (product.name === "Up Social") {
            product.order = 6;
          } else if (product.name === "Up Gradinita") {
            product.order = 7;
          } else {
            product.order = 8;
          }
        });

        this.affiliate.products.sort((a, b) => (a.order > b.order ? 1 : -1));
      }
    },
    loadAffiliateData() {
      if (Cookies.get("userLink") !== null) {
        this.userLink = Cookies.get("userLink");
        this.token = Cookies.get("token");
      } else {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: true }
        });
      }

      if (localStorage.getItem("affiliate_data")) {
        this.affiliate = JSON.parse(localStorage.getItem("affiliate_data"));
        this.dataLoaded = true;
      } else {
        this.showModalPopup("modal_popup_loading", true);
        httpServiceUser.userService
          .getCompanyData(this.company.company_id, Cookies.get("token"), "AFF")
          .then(function(response) {
            return response;
          })
          .catch(error => {
            this.dataLoaded = false;
            this.affiliateError = {
              code: error.response.status,
              description: "Error fetching affiliate"
            };
            this.showModalPopup("modal_popup_loading", false);
            window.location.href = "/?serverError=500";
          })
          .then(response => {
            if (response.status == 200) {
              this.affiliate = response.data;
              if (
                this.affiliate.products.length === 0 &&
                this.company.role !== "AFA"
              ) {
                this.showModalPopup("modal_popup_noProducts", true);
              }
              this.orderProducts();
              this.checkHasElectronicProducts();
              localStorage.setItem(
                "affiliate_data",
                JSON.stringify(this.affiliate)
              );
              this.dataLoaded = true;
            } else if (response.status == 400 || response.status == 403) {
              this.dataLoaded = false;
              this.affiliateError = {
                code: response.data,
                description: "Error fetching transactions"
              };
            } else {
              this.dataLoaded = false;
              this.affiliateError = { description: response.description };
            }
            this.showModalPopup("modal_popup_loading", false);
          });
      }
    },
    loadMessages() {
      let formData =
        "?user_id=" +
        this.affiliate.company_id +
        "&lang=" +
        localStorage.language;
      httpServiceAffiliate.affiliateService
        .getMessages(formData, this.token)
        .then(function(response) {
          // handle success
          return response;
        })
        .then(response => {
          // total no of messages X-Up-Count
          // no of read messages
          this.readMessagesCount = response.headers["x-up-count1"]; //["Content-Length"];
          // no of unread messages X-Up-Count2
          this.affiliateMessages = response.data;
        })
        .catch(error => {
          // handle error
        });
    },
    openMenu(open) {
      if (open) {
        $(".menu-mobile").show();
      } else {
        $(".menu-mobile").hide();
      }
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    openNotifications() {
      this.loadMessages();
      this.isOpenNotification = !this.isOpenNotification;
    },
    isReadNotification(message) {
      message.read = true;
      httpServiceAffiliate.affiliateService
        .markMessageAsRead(message, this.token)
        .catch(error => {
          // handle success
        });
    },
    hide() {
      this.isOpen = false;
    },
    hideNotification() {
      this.isOpenNotification = false;
    },
    getPageTitle() {
      if (this.menuSelected.cardTransactions) {
        return "Card Transactions";
      } else if (this.menuSelected.repayment) {
        return "Repayment";
      } else if (this.menuSelected.invoices) {
        return "Invoices";
      } else if (this.menuSelected.workingPoints) {
        return "Working points";
      } else if (this.menuSelected.pos) {
        return "POS";
      } else if (this.menuSelected.statistics) {
        return "Statistics";
      } else if (this.menuSelected.stickers) {
        return "Stickers";
      } else if (this.menuSelected.account) {
        return "My Account";
      } else if (this.menuSelected.manageCompany) {
        return "Manage Company";
      } else if (this.menuSelected.contact) {
        return "Contact";
      } else if (this.menuSelected.terms) {
        return "Terms and conditions";
      } else if (this.menuSelected.gdpr) {
        return "Personal data policy";
      } else if (this.menuSelected.additionalDocuments) {
        return "Additional documents";
      } else if (this.menuSelected.affiliateSearch) {
        return "Affiliate search";
      } else if (this.menuSelected.offers) {
        return "Offers";
      } else {
        return "Dashboard";
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    sendEmailFeedback() {
      let formData = {
        code: this.affiliate.company_id,
        description: this.feedbackMessage
      };
      httpServiceCustomer.customerService
        .sendFeedbackMail(formData, Cookies.get("token"), "AFF")
        .then(function(response) {
          // handle success
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.successSentFeedback = true;
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.sentFeedbackError = response.response;
          } else if (response.status == 404) {
            // show file not found
            this.sentFeedbackError = response.response;
          }
        })
        .catch(error => {
          // handle success
          this.sentFeedbackError = {
            code: error.response.status
          };
        });
    },

    checkHasElectronicProducts() {
      let electronicProducts = this.affiliate.products.filter(x => x.card > 0);
      if (electronicProducts.length > 0) {
        this.hasElectronicProduct = true;
      }
    },
  },
  computed: {
    hasPaperProduct() {
      for (let index in this.affiliate.products) {
        if (this.affiliate.products[index].paper > 0) {
          return true;
        }
      }
      return false;
    },
    hasCardProduct() {
      for (let index in this.affiliate.products) {
        if (this.affiliate.products[index].card > 0) {
          // this.hasElectronicProduct = true;
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.loadAffiliateData();
    if (!sessionStorage.getItem("affiliate_jsonFile")) {
      httpService.commonsService.getConfigFile(
        localStorage.language,
        "affiliate"
      );
    }
    // if (this.checkNpsSurvey("nps_afiliat") < 4){
    //   this.showModalPopup("modal_popup_nps", true);
    // }
  },
  created() {
    switch (this.$route.name) {
      case "affiliate_cardTransactions":
        this.menuSelected.cardTransactions = true;
        break;
      case "affiliate_repayment":
        this.menuSelected.repayment = true;
        break;
      case "affiliate_invoices":
        this.menuSelected.invoices = true;
        break;
      case "affiliate_workingPoints":
        this.menuSelected.workingPoints = true;
        break;
      case "affiliate_pos":
        this.menuSelected.pos = true;
        break;
      case "affiliate_statistics":
        this.menuSelected.statistics = true;
        break;
      case "affiliate_stickers":
        this.menuSelected.stickers = true;
        break;
      case "affiliate_account":
        this.menuSelected.account = true;
        break;
      case "affiliate_contact":
        this.menuSelected.contact = true;
        break;
      case "affiliate_terms":
        this.menuSelected.terms = true;
        break;
      case "affiliate_gdpr":
        this.menuSelected.gdpr = true;
        break;
      case "affiliate_additionalDocuments":
        this.menuSelected.additionalDocuments = true;
        break;
      case "affiliate_offers":
        this.menuSelected.offers = true;
        break;
      case "manage_company":
        this.menuSelected.manageCompany = true;
        break;
      default:
        if (this.company.role === "AFA") {
          this.menuSelected.affiliateSearch = true;
        } else {
          this.menuSelected.dashboard = true;
        }
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
